@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
    background-color: rgba(58, 21, 76, 0.57);
    color: #b07575;
}

span, p, h1, h2, h3, h4, h5, h6, a, ul, li, button, input, label, select, textarea {
    font-family: 'Montserrat', serif !important;
}
html {
    scroll-behavior: smooth;
}

body {
    margin: auto;
    font-family: 'Montserrat', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #050213;
}

ul {
    list-style: none;
}

/*SERVİCE CARD*/
.serviceCard {
    /*width: 100%;*/
    /*max-width: 300px;*/
    /*min-width: 300px;*/
    height: 250px;
    background-color: #050213;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(190, 185, 185, 0.45);
    border: 2px solid rgba(7, 7, 7, 0.12);
    font-size: 16px;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
}

.icon {
    margin: 0 auto;
    width: 100%;
    height: 80px;
    max-width: 80px;
    background: linear-gradient(90deg, #7eaaff 0%, #ff48fb 40%, rgba(0, 0, 0, 0.28) 60%);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.8s ease;
    background-size: 200px;
}

.icon svg {
    fill: white;
}

.serviceCard .title {
    width: 100%;
    /*margin: 0;*/
    text-align: center;
    margin-top: 30px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4px;
}

.serviceCard .text {
    width: 80%;
    /*margin: 0 auto;*/
    font-size: 13px;
    text-align: center;
    margin-top: 20px;
    color: white;
    font-weight: 200;
    letter-spacing: 2px;
    opacity: 0;
    max-height: 0;
    transition: all 0.3s ease;
}

.serviceCard:hover {
    height: 270px;
}

.serviceCard:hover .text {
    transition: all 0.3s ease;
    opacity: 1;
    max-height: 60px;
}

.serviceCard:hover .icon {
    background-position: -120px;
    transition: all 0.3s ease;
}

.serviceCard:hover .icon svg path {
    fill: url('#gradientColor');
    transition: all 0.3s ease;
}

/*SERVİCE CARD*/
.card {
    width: 200px;
    height: 200px;
    background: linear-gradient(90deg, #7eaaff 0%, #ff48fb 40%, rgba(0, 0, 0, 0.28) 60%);
    border-radius: 20px;
    transition: all 0.3s;
    border: 2px solid #7eaaff; /* Border rengi */
    box-shadow: 0 0 20px rgba(126, 170, 255, 0.6); /* Shadow rengi */
}

.card2 {
    width: 200px;
    height: 200px;
    background-color: #1a1a1a;
    border-radius: 20px;
    transition: all 0.2s;
    border: 2px solid #1a1a1a; /* Border rengi */
}

.card:hover {
    box-shadow: 0 0 30px 1px rgba(255, 72, 251, 0.6);
    border: 2px solid #ff48fb; /* Hover durumunda border rengi */
}

.card2:hover {
    transform: scale(0.98);
    border: 2px solid #ff48fb; /* Hover durumunda border rengi */
}


/*FOOTER ICONS*/
.wrapper {
    display: inline-flex;
    list-style: none;
    /*height: 120px;*/
    /*width: 100%;*/
    /*padding-top: 40px;*/
    /*font-family: "Poppins", sans-serif;*/
    justify-content: center;
}

.wrapper .icon {
    position: relative;
    background: #FFFFFF33;
    border-radius: 50%;
    /*margin: 10px;*/
    width: 50px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(19, 19, 19, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background: #fff;
    color: #fff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: #fff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
    background: #1877F2;
    color: #fff;
}

.wrapper .twitter:hover,
.wrapper .twitter:hover .tooltip,
.wrapper .twitter:hover .tooltip::before {
    background: #1DA1F2;
    color: #fff;
}

.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
    background: #E4405F;
    color: #fff;
}

.react-tel-input {
    :focus {
        outline: none !important;
    }

    :focus-visible {
        outline: none !important;
    }
}

.react-tel-input .form-control {
    width: 100% !important;
    background-color: transparent !important;
    color: #4f5564 !important;
    font-family: sans-serif !important;
    font-weight: normal !important;
    /*outline: 1px solid #000 !important;*/
    outline: none !important;
    transition: all 0.3s ease !important;

    &::placeholder {
        border: 1px solid rgb(162, 162, 162) !important;
    }

    box-shadow: none !important;

    &:focus {
        border: 1px solid rgb(162, 162, 162) !important;

        &::placeholder {
            border: 1px solid rgb(162, 162, 162) !important;
        }
    }

    font-size: 0.875rem !important;
    padding: 12px 12px 12px 50px !important;
    border-radius: 0.375rem !important;
    border: 1px solid #4f5564 !important;
}

.react-tel-input .selected-flag.open::before {
    border-color: transparent !important;
    box-shadow: none !important;
}

.react-tel-input .selected-flag:focus::before, .react-tel-input .selected-flag.open::before {
    border-color: transparent !important;
    box-shadow: none !important;
}

.search-box {
    width: 90%;
}

/*COLOR PATTERN*/

.gradientPattern {
    background: linear-gradient(45deg, #7eaaff 5%, #ff48fb 95%);
    -webkit-background-clip: text; /* Clip the text to the background */
    background-clip: text; /* Standard property for modern browsers */
    color: transparent; /* Set the text color to transparent */
    display: inline-block; /* Allow inline-block display to apply background-clip */
}

.backgroundGradient {
    position: relative;
    padding: 10px 20px;
    border-radius: 7px;
    border: 1px solid #242466;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    background: #191728;
    color: #fff;
    overflow: hidden;
    box-shadow: 0 0 0 0 transparent;
    transition: all 0.2s ease-in;
    font-family: 'Montserrat', serif;
}

.backgroundGradient:hover {
    background: #31245f;
    border: 1px solid #1e1e43;
    box-shadow: 0 0 30px 5px rgb(55, 49, 137);
    transition: all 0.2s ease-out;
}

.backgroundGradient:hover::before {
    animation: sh02 0.5s 0s linear;
}

.backgroundGradient::before {
    content: '';
    display: block;
    width: 0px;
    height: 86%;
    position: absolute;
    top: 7%;
    left: 0%;
    opacity: 0;
    background: #d4dbde;
    box-shadow: 0 0 50px 30px #d4dbde;
    transform: skewX(-20deg);
}

@keyframes sh02 {
    from {
        opacity: 0;
        left: 0%;
    }

    50% {
        opacity: 1;
    }

    to {
        opacity: 0;
        left: 100%;
    }
}

.backgroundGradient:active {
    box-shadow: 0 0 0 0 transparent;
    transition: box-shadow 0.2s ease-in;
}


.shadowText {
    text-shadow: .1em .1em .2em rgba(255, 255, 255, 0.5)
}

/*FLİP CARD CSS*/
.containerFlip {
    width: 280px;
    height: 230px;
    /*background: url("https://img.freepik.com/free-vector/mobile-marketing-concept-illustration_114360-1497.jpg?w=740&t=st=1701857533~exp=1701858133~hmac=8465510def738af7d895db8a644120dd77e1202b6d90583d3873a6f3e94a0c1e");*/
    background-color: rgb(0, 0, 0);
    background-size: cover;
    -webkit-box-shadow: 0 0 5px #000;
    box-shadow: 0 0 5px #000;
}

.overlay {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 2fr 1fr;
    background: rgba(77, 77, 77, 0.9);
    color: #fef5df;
    opacity: 0;
    transition: all 0.5s;
    font-family: "Playfair Display", serif;
}

.items {
    padding-left: 20px;
    letter-spacing: 3px;
}

.head {
    font-size: 30px;
    line-height: 30px;
    transform: translateY(40px);
    transition: all 0.7s;
}

.head hr {
    display: block;
    width: 0;
    border: none;
    border-bottom: solid 2px #fef5df;
    position: absolute;
    bottom: 0;
    left: 20px;
    transition: all 0.5s;
}

.price {
    font-size: 22px;
    line-height: 10px;
    font-weight: bold;
    opacity: 0;
    transform: translateY(40px);
    transition: all 0.7s;
}

.price .old {
    text-decoration: line-through;
    color: #b3b3b3;
}

.cart {
    font-size: 12px;
    opacity: 0;
    letter-spacing: 1px;
    font-family: "Lato", sans-serif;
    transform: translateY(40px);
    transition: all 0.7s;
}

.cart i {
    font-size: 16px;
}

.cart span {
    margin-left: 10px;
}

.containerFlip:hover .overlay {
    opacity: 1;
}

.containerFlip:hover .overlay .head {
    transform: translateY(0px);
}

.containerFlip:hover .overlay hr {
    width: 50%;
    transition-delay: 0.4s;
}

.containerFlip:hover .overlay .price {
    transform: translateY(0px);
    transition-delay: 0.3s;
    opacity: 1;
}

.containerFlip:hover .overlay .cart {
    transform: translateY(0px);
    transition-delay: 0.6s;
    opacity: 1;
}

/*TEXT TRUNCATE*/
.truncate-text-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.truncate-text-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.truncate-text-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


/*active and focus bachround color [#1c1929] border [#1c1929]*/
.sideliststle:focus {
    background-color: #1c1929 !important;
    box-shadow: none !important;
    border: 1px solid #1c1929 !important;
}

.sideliststle:active {
    background-color: #1c1929 !important;
    box-shadow: none !important;
    border: 1px solid #1c1929 !important;
}


/*border bottom left to right aniamtion*/
.borderAnimate {
    display: inline-block;
    color: #dad7d7;
    text-decoration: none;
    position: relative;
}

.borderAnimate::after {
    position: absolute;
    bottom: 0;
    content: '';
    display: block;
    width: 0;
    height: 1.3px;
    background: #dad7d7;
    transition: width .5s;
    right: 0;
    left: initial;
}

.borderAnimate:hover::after {
    width: 100%;
    right: initial;
    left: 0;
    /*transition: width .3s;*/
}

.sideBorder {
    /*background:#00ccff;*/
    /*margin:10px;*/
    padding: 7px;
    float: left;
    box-sizing: border-box;
    transition: 0.5s all ease-in;
    position: relative;
    cursor: pointer;
}

.sideBorder:before {
    position: absolute;
    bottom: 0;
    background: #e8e8e8;
    height: 2px;
    display: block;
    content: "";
    width: 0;
    transition: 0.3s all ease-in-out;
}

.sideBorder:before {
    bottom: 0;
}

.sideBorder:hover:before {
    width: 2px;
    height: 100%;
}

.sideBorderL:before {
    left: 0
}


.footer {
    background-color: #151515;
    padding-top: 20px;
}

.container {
    max-width: 1170px;
    margin: auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

ul {
    list-style: none;
}

.footer-col {
    width: 25%;
}

.footer-col h4 {
    font-size: 18px;
    color: #FFF;
    text-transform: capitalize;
    margin-bottom: 20px;
    font-weight: 500;
    position: relative;
}

.footer-col h4::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    background: #7eaaff;
    width: 60px;
    height: 2px;
}

.footer-col ul li:not(:last-child) {
    margin-bottom: 10px;
}

.footer-col ul li a {
    color: #DDD;
    display: block;
    font-size: 1rem;
    font-weight: 300;
    text-transform: capitalize;
    text-decoration: none;
    transition: all 0.3s ease;
}

.footer-col ul li a:hover {
    color: #FFF;
    padding-left: 7px;
}

/*.footer-col .social-links a {*/
/*    color: #FFF;*/
/*    background-color: rgba(255, 255, 255, 0.2);*/
/*    display: inline-block;*/
/*    height: 40px;*/
/*    width: 40px;*/
/*    border-radius: 50%;*/
/*    text-align: center;*/
/*    margin: 0 10px 10px 0;*/
/*    line-height: 40px;*/
/*    transition: all 0.5s ease;*/
/*}*/

/*.footer-col .social-links a:hover {*/
/*    color: #151515;*/
/*    background-color: #FFF;*/
/*}*/

@media (max-width: 767px) {
    .footer-col {
        width: 50%;
        margin-bottom: 30px;
    }
}

@media (max-width: 574px) {
    .footer-col {
        width: 100%;
    }
}